import React from 'react';
import { Table, Button, Input } from 'antd';
import { API } from "../config";
import moment from 'moment';
import {EditOutlined} from "@ant-design/icons";
import { Link } from 'react-router-dom';

const getDate = (n) => {
    n = n.replace('.json', '')
    let params = n.split('.');
    return (new Date(params[2] + '-' + params[1] + '-' + params[0])).getTime();
}

export class FilesListController extends React.Component {
    constructor(props) {
        super(props);
        console.log(`props`, props);
        this.state = {
            loading: false,
            list: [],
        };
    }

    loadList = () => {
        if (this.state.loading === false) {
            this.setState({ loading: true }, () => {
                fetch(`${API}/list`).then(res => res.json()).then(res => {
                    let  list = res.list || [];
                    list.sort((a,b) => {
                        return getDate(b.name) - getDate(a.name);
                    })
                    this.setState({loading: false, list});
                }).catch(e => {
                    console.error(`[failed to update]: `, e);
                });
            });
        }
    }

    componentDidMount() {
        this.loadList();
    }

    render() {
        return (
            <div className={`content--wrapper`}>
                <Table
                    loading={this.state.loading}
                    dataSource={this.state.list}
                    rowKey={(r) => {
                        return r.name
                    }}
                    columns={[
                        {
                            key: 'filename',
                            title: 'File name',
                            render: r => r.name,
                        },
                        {
                            key: 'updated_at',
                            title: 'Update at',
                            render: r => moment(r.updated_at).format('LLL'),
                        },
                        {
                            key: 'actions',
                            title: '',
                            render: r => {
                                return <Link to={`/edit/${r.name.replace('.json', '')}`}><Button type={`primary`} icon={<EditOutlined />}>Edit</Button></Link>
                            }
                        }
                    ]}
                    pagination={{
                        pageSize: this.state.list.length,
                        limit: this.state.list.length,
                        hideOnSinglePage: true,
                    }}
                />
            </div>
        );
    }
}

