import { Flex, Layout, Menu } from 'antd';
import { Routes, Route, Link } from "react-router-dom";
import './style/indes.scss';
import {FilesListController} from "./controllers/FilesList.controller";
import {FilesEditController} from "./controllers/FilesEdit.controller";
import {MapController} from "./controllers/Map.controller";
import {MapLayout} from "./controllers/map";

function App() {
  return (
      <Flex gap="middle" wrap>
        <Layout>
            <Layout.Header>
                <div className="header--wrapper">
                    <div className={`logo`}>
                        <svg className="Icon-root" width="101" height="99.99" viewBox="0 0 101 100" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M100.467 0L0.468262 0V99.9997H100.467V0Z" fill="#77BC1F"></path>
                            <path
                                d="M49.8438 35.1251C52.0597 35.2047 54.0596 36.1592 55.5142 37.6479C57.0369 39.2047 57.98 41.3524 57.98 43.7274C57.98 46.1137 57.0482 48.2615 55.5142 49.8069C54.571 50.7615 53.4233 51.4887 52.1165 51.9092L61.73 64.8864H44.0825L35.5257 52.3296H30.628V64.8864H13.8555V35.1251C13.8555 35.1251 28.8781 35.1251 49.8438 35.1251ZM36.6053 48.6933C37.8439 48.6251 38.9689 48.091 39.7984 47.2387C40.6848 46.3297 41.2302 45.091 41.2302 43.7274C41.2302 42.3638 40.6848 41.1251 39.7984 40.216C38.9689 39.3751 37.8439 38.8297 36.6053 38.7615H30.628V48.6933H36.6053Z"
                                fill="black"></path>
                            <path
                                d="M56.3323 35.1251V38.7615H70.389V64.8864H87.1389V38.7615H100.457L100.468 35.1138L56.3323 35.1251Z"
                                fill="black"></path>
                        </svg>
                    </div>
                    <div className="menu">
                        <Menu mode={`horizontal`} theme={`dark`}>
                            <Menu.Item><Link to={"/"}>Files</Link></Menu.Item>
                            <Menu.Item><Link to={"/map"}>Map</Link></Menu.Item>
                        </Menu>
                    </div>
                </div>

            </Layout.Header>
                <Routes>
                     <Route path="/" element={<Layout.Content><FilesListController /></Layout.Content>} />
                    <Route path="/edit/:id" element={<Layout.Content><FilesEditController /></Layout.Content>} />
                    <Route path="/map" element={<MapLayout />} />
                </Routes>
        </Layout>
      </Flex>
  );
}

export default App;
