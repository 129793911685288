import React, { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Table, Input, message } from 'antd';
import {API} from "../config";

export const FilesEditController = (props) => {
    const [id, setId] = useState(useParams().id);
    const [ items, setItems] = useState([]);
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setLoading(true);
        fetch(`${API}/file/${id}`).then(res => res.json()).then(res => {
            setLoading(false);
            setItems(res);
        }).catch(e => {
            alert(`Failed to load`);
        })
    }, [id]);

    const saveChanges = () => {
        setLoading(true);
        fetch(`${API}/file/${id}`, {
            method: "POST",
            body: JSON.stringify(items)
        }).then(res => res.json()).then(res => {
            message.success(`file has been updated`);
            setLoading(false);
        }).catch(e => {
            console.error(`[file.save]: `, e);
            message.error(`Failed to save file`);
            setLoading(false);
        });
    }



    return (
        <div className={`content--wrapper`} dir={"rtl"}>
            <Table
                loading={loading}
                dataSource={items}
                rowKey={(r, index) => {
                    return `row_index_${index}`;
                }}
                columns={[
                    {
                        key: 'text',
                        title: 'text',
                        render: (r, _, index) => (
                            <Input.TextArea
                                onBlur={() => {
                                    saveChanges();
                                }}
                                autoSize={{ minRows: 6, maxRows: 12 }}
                                value={r.text}
                                onChange={(e) => {
                                    let l = [...items];
                                    l[index]["text"] = e.target.value;
                                    setItems(l);
                                }}
                            />
                        )
                    },
                    {
                        key: 'name',
                        title: 'name',
                        render: (r, _, index) => (
                            <Input
                                value={r.name}
                                onBlur={() => {
                                    saveChanges();
                                }}
                                onChange={(e) => {
                                    let l = [...items];
                                    l[index]["name"] = e.target.value;
                                    setItems(l);
                                }}
                            />
                        )
                    },
                    {
                        key: 'area',
                        title: 'area',
                        render: (r, _, index) => (
                            <Input
                                value={r.area}
                                onBlur={() => {
                                    saveChanges();
                                }}
                                onChange={(e) => {
                                    let l = [...items];
                                    l[index]["area"] = e.target.value;
                                    setItems(l);
                                }}
                            />
                        )
                    }
                ]}
                pagination={{
                    pageSize: items.length,
                    limit: items.length,
                    hideOnSinglePage: true,
                }}
            />
        </div>
    );
}