import React from 'react';
import {CityIcon, Icons} from './icons';
import DatePicker, {registerLocale} from "react-datepicker";
import {ar} from 'date-fns/locale/ar';
import L from 'leaflet'
import FreeDraw from 'leaflet-freedraw';
import 'leaflet-draw/dist/leaflet.draw';
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet/dist/leaflet.css';
import './style.scss';
import "react-datepicker/dist/react-datepicker.css";

registerLocale('ar', ar)

L.RasterCoords = require('./rastercoords');
let Data = require('./cities.json').map(i => {
    let b = {
        ...i, ...{
            lat: i.lat.replace(",", "."),
            lng: i.lng.replace(",", "."),
            population: parseInt(i.population, 10),
        }
    }
    //eslint-disable-next-line
    b.zoom = b.zoom && 1 === b.zoom || (b.population >= 1e5 ? b.zoom = b.zoom || 2 : b.population >= 5e4 ? b.zoom = b.zoom || 3 : b.zoom = b.zoom || 4)
    return b;
});



Data.sort((a, b) => {
    return b.population - a.population;
});


let Cities = Data.filter(i => !i.type);
let Countries = Data.filter(i => i.type === 'country');
Countries.push({
    lat: "45.22",
    lng: "34.41",
    name: "КРЫМ",
    population: NaN,
    rotate: "",
    title:"القرم",
    type:"country",
    zoom:"1"
});

let Seas = Data.filter(i => i.type === 'sea');
const Directions = require('./directions.json');
const Dates = require('./dates.json');


const inView = (t, e) => {
    try {
        let n = t.getBoundingClientRect(),
            i = e.getBoundingClientRect(),
            r = n.x < i.x + i.width && n.x + n.width > i.x,
            o = n.y < i.y + i.height && n.y + n.height > i.y;
        return r && o
    } catch (t) {
        return !1
    }
}

const HOST = 'https://cdn.rt.com/map_tiles';

const deviceSettings = {
    "desktop": {
        "min-zoom": 3,
        "max-zoom": 7,
        "size": [28800, 28800],
        "tile-size": 300,
        "view": [49, 33],
        "transformation": [2.497e-8, -0.0573, -3.399e-8, 0.2705],
        "scale": [13.12, 9.63]
    },
    "mobile": {
        "min-zoom": 2,
        "max-zoom": 6,
        "size": [14400, 14400],
        "tile-size": 150,
        "view": [49, 34],
        "transformation": [2.495320233665337e-8, -0.05715, -3.4e-8, 0.2705],
        "scale": [13.12, 9.63]
    }
}


const convertDate = (date) => {
    let params = date.split('.');
    return params[2] + '-' + params[1] + '-' + params[0];
}


const DatepickerDates = Dates.map(i => {
    return new Date(convertDate(i));
})

const getLeadZero = (value) => {
    let vd = parseInt(value);
    return vd < 10 ? `0${vd}` : vd;
}

const ClassPositions = {
    "лево": "position_left",
    "снизу": "position_bottom",
    "сверху": "position_up",
    "право": "position_right",
}

export class MapLayout extends React.Component {
    constructor(props) {
        super(props);
        const mapType = this.isMobile() ? "mobile" : "desktop";
        this.state = {
            config: deviceSettings[mapType],
            mapType: mapType,
            zoom: 3,
            selectedDate: new Date(convertDate(Dates[2])),
            dateIndex: 2,
            events: [],
            loading: false,
            direction: null,
            popup_open: false,
            popup_position: false,
            popup_point: null,
        };
        this.map = null;
        this.rcoords = null;
        this.overlay = null;
    }


    loadEvents = () => {
        const {selectedDate} = this.state;
        let fileIndex = `${getLeadZero(selectedDate.getDate())}.${getLeadZero(selectedDate.getMonth() + 1)}.${selectedDate.getFullYear()}`;
        if (this.state.loading === false) {
            this.setState({loading: true}, () => {
                if (this.state.events && Object.keys(this.state.events).length > 0) {
                    for (let i in this.state.events) {
                        this.map.removeLayer(this.state.events[i].marker);
                    }
                }
                let host = window.location.hostname.indexOf('localhost') + 1 > 0 ? `http://localhost:8080/api` : `/api`;
                fetch(`${host}/file/${fileIndex}?v=002`).then(res => res.json()).then(res => {
                    this.setState({loading: false, events: res}, () => {
                        this.addEvents();
                        this.addMapOverlay();
                    })
                }).catch(e => {
                    console.error(`[events.load]`, e);
                    this.setState({loading: false});
                })
            })
        }
    }

    isMobile = () => {
        return window.innerWidth <= 450;
    }

    viewToMap = () => {
        const {config} = this.state;
        let n = this.map.project(config.view);
        let i = this.map.unproject({
            x: n.x * config.scale[0],
            y: n.y * config.scale[1]
        });
        return [i.lat, i.lng]
    }

    viewFromMap = () => {
        const {config} = this.state;
        let e = this.map.project(config.view);
        let n = this.map.unproject({
            x: e.x / config.scale[0],
            y: e.y / config.scale[1]
        });
        return [n.lat, n.lng]
    }

    getMarkerIcon = (point) => {
        if (point.population) {
            let zoomIndex = "" + point.zoom;
            if (CityIcon[zoomIndex]) {
                return CityIcon[zoomIndex];
            }
        }
        return '';
    }

    buildPointName = (name) => {
        return name.split(' ').join("\n");
    }

    getTemplate = (point, icon, isEvent = false) => {
        let div = document.createElement('div');
        div.classList.add('map--point');
        div.dataset.name = point.name;
        let markerType = isEvent ? `event` : `city`;
        if (['country', 'sea'].indexOf(point.type) + 1 === 0) {
            if (icon) {
                div.innerHTML = `<div class="marker--icon event--icon"><div class="icon--default">${icon.id}</div>${icon.zoomed ? `<div class="icon--zoomed">${icon.zoomed}</div>` : ``}</div>`;
            } else {
                div.innerHTML = `<div class="marker--icon">${this.getMarkerIcon(point)}</div>`;
            }
        } else {
            markerType = point.type;
        }
        const title = point.title || point.name;
        div.innerHTML += `
             <div data-zoom="" class="marker--text zoom-${point.zoom || 0} marker--type_${markerType}" data-name="${point.name}">${this.buildPointName(title)}</div>
        `;
        return div;
    }

    addMarkerToMap = (point, zoom, isEvent = false) => {
        // t, e, i, r, o, a, s
        // n, e, t.map, t.config, "true" === t.props.params.i, "hide" === t.props.params.n
        if (!point.marker && point.lat && point.lng) {
            point.marker = null;

            let l = point.icon ? "string" == typeof point.icon ? point.icon : point.icon[zoom - 1] instanceof Array ? point.icon[zoom - 1][0] : point.icon[zoom - 1] : null;
            let c = l ? Icons.find((function (t) {
                return t.name === l.split(", ")[0]
            })) : null;

            let pos = this.map.project(point);
            let html = this.getTemplate(point, c, isEvent);
            point.htmlNode = html;
            point.nameNode = html.querySelector('.marker--text')
            point.iconNode = html.querySelector('.marker--icon')


            if (point.name_position && ClassPositions[point.name_position]) {
                html.classList.add(ClassPositions[point.name_position])
            }

            if (['country', 'sea'].indexOf(point.type) + 1 > 0 && point.rotate) {
                point.nameNode.style.transform = `rotate(${point.rotate}deg)`;
            }


            point.marker = new L.marker(this.map.unproject({
                x: pos.x * this.state.config.scale[0],
                y: pos.y * this.state.config.scale[1],
            }), {
                icon: L.divIcon({
                    html: html,
                })
            });

            point.marker.preoriti = /удары Украины/i.test(point.icon) ? 1 : /населенные пункты за минувшие сутки/i.test(point.icon) ? 3 : /места боестолкновений/i.test(point.icon) ? 4 : 2;
            point.marker.zIndex = 1e3 + 1e3 * (point.marker.preoriti || 0) + 1e3 * (4 - (point.name_show || 4));
            point.marker.setZIndexOffset(point.marker.zIndex);
            point.marker.on({
                mouseover: (e) => {
                    point.htmlNode.classList.add('hovered');
                },
                mouseout: () => {
                    point.htmlNode.classList.remove('hovered');
                },
                click: (e) => {

                    let event = {
                        x: e.layerPoint.x,
                        y: e.layerPoint.y
                    }
                    if (document.querySelector('.marker--icon.active')) {
                        document.querySelector('.marker--icon.active').classList.remove('active');
                    }
                    if (point.iconNode.querySelector('g#click')) {
                        point.iconNode.classList.add('active');
                    }
                    document.getElementById('map--content').innerHTML = `
                            <div class="modal--title">${point.name}${point.area ? `, ${point.area}` : ''}</div>
                            <div class="modal--text">${point.text}</div>
                        `;
                    if (this.state.zoom !== 5) {
                        this.map.flyTo(point.marker._latlng, 5);
                        setTimeout(() => {
                           this.popupOpen(point)
                        }, 600);
                    } else {
                        this.popupOpen(point)
                    }
                }
            })


            point.marker.addTo(this.map);
        }
        return point;
    }

    popupClose = () => {
        if (document.querySelector('.marker--icon.active')) {
            document.querySelector('.marker--icon.active').classList.remove('active');
        }
        document.getElementById('map--popup').classList.remove('visible');
    }

    popupOpen = (point) => {
        let pointBound = point.iconNode.getBoundingClientRect();
        document.getElementById('map--popup').style.transform = `translate(${pointBound.x - 5}px, ${pointBound.y - 25}px)`;
        setTimeout(() => {
            document.getElementById('map--popup').classList.add('visible');
        }, 50);
    }

    getEventsAsSlice = () => {
        let result = [];
        for (let i in this.state.events) {
            result.push(this.state.events[i]);
        }
        return result;
    }

    filterEvents = () => {
        let t = this.state.zoom - this.state.config["min-zoom"] + 1;
        let events = this.getEventsAsSlice();
        if (events) {
            events = events.map(i => {
                i.status = "visible";
                return i;
            })

            for (let i = 0; i < events.length; i++) {
                if (events[i].name_show) {
                    if (events[i].name_show <= t) {
                        events[i].nameNode.classList.add("title_visible");
                    } else {
                        events[i].nameNode.classList.remove("title_visible");
                    }
                } else {
                    for (let j = 0; j < events.length; j++) {
                        if (i !== j) {
                            if ("visible" === events[i].status && inView(events[i].nameNode, events[j].nameNode) || inView(events[i].nameNode, events[j].iconNode)) {
                                events[i].nameNode.classList.remove("title_visible")
                                events[i].status = "hidden";
                                break
                            }
                        }
                        if (j === events.length) {
                            events[i].nameNode.classList.add("title_visible")
                        }
                    }
                }
            }
        }
    }

    filterMarkers = () => {
        // Is(this.cities, this.data, this.map, this.zoom, this.config)
        // Is = function(t, e, n, i, r)
        const {zoom, config} = this.state;
        let realZoom = zoom - config["min-zoom"];
        let mapBound = this.map.getBounds();
        let list = [];
        const events = this.getEventsAsSlice();

        let eventsB = events.filter(i => {
            if (!i.marker) {
                return false;
            }
            return mapBound.contains(i.marker.getLatLng());
        });

        Cities.forEach((t) => {
            if (t.zoom <= realZoom + 1 && mapBound.contains(t.marker.getLatLng())) {
                this.map.addLayer(t.marker);
                list.push(t);
            } else {
                this.map.removeLayer(t.marker);
            }
        });

        list.sort((a, b) => {
            return b.population - a.population;
        });
        for (let i = 0; i < list.length; i++) {
            if (eventsB) {

                for (let j = 0; j < eventsB.length; j++) {
                    if (list[i].name === eventsB[j].name) {
                        this.map.removeLayer(list[i].marker);
                    } else {
                        if ("1" === list[i].zoom) {
                            if (inView(list[i].nameNode, eventsB[j].nameNode)) {
                                eventsB[j].nameNode.classList.remove("title_visible");
                            } else {
                                eventsB[j].nameNode.classList.add("title_visible");
                            }
                        } else {
                            if (inView(list[i].nameNode, eventsB[j].iconNode) || inView(list[i].iconNode, eventsB[j].iconNode) || inView(list[i].nameNode, eventsB[j].nameNode)) {
                                this.map.removeLayer(list[i].marker);
                            }
                        }
                    }
                }
            }
            for (let j = 0; j < list.length; j++) {
                if (j !== i) {
                    if (inView(list[i].nameNode, list[j].nameNode) || inView(list[i].nameNode, list[j].iconNode) || inView(list[i].iconNode, list[j].nameNode)) {
                        if (list[i].zoom && !list[j].zoom) {
                            this.map.removeLayer(list[j]);
                        } else {
                            if (list[j].zoom && !list[i].zoom) {
                                this.map.removeLayer(list[j]);
                            } else {
                                if (list[j].zoom && list[i].zoom && list[i].zoom !== list[i].zoom) {
                                    this.map.removeLayer(list[i].zoom < list[j].zoom ? list[j].marker : list[i].marker);
                                } else {
                                    this.map.removeLayer(list[i].population > list[j].population ? list[j].marker : list[i].marker)
                                }
                            }
                        }
                    }
                }
            }
        }
        this.filterEvents();
    }

    addCities = () => {
        const zoom = this.state.zoom - this.state.config["min-zoom"] + 1;
        Cities = Cities.map(i => {
            return this.addMarkerToMap(i, zoom);
        });
    }

    addCountries = () => {
        const zoom = this.state.zoom - this.state.config["min-zoom"] + 1;
        Countries = Countries.map(i => {
            return this.addMarkerToMap(i, zoom)
        })
    }

    addSeas = () => {
        const zoom = this.state.zoom - this.state.config["min-zoom"] + 1;
        Seas = Seas.map(i => {
            return this.addMarkerToMap(i, zoom)
        })
    }

    addEvents = () => {
        const zoom = this.state.zoom - this.state.config["min-zoom"] + 1;
        let events = {...this.state.events};
        for (let i in events) {
            events[i] = this.addMarkerToMap(events[i], zoom, true)
        }
        this.setState({events}, () => {
            this.filterMarkers();
        });
    }

    addMapOverlay = (date = '') => {
        const {selectedDate} = this.state;
        let fileIndex = `${getLeadZero(selectedDate.getDate())}.${getLeadZero(selectedDate.getMonth() + 1)}.${selectedDate.getFullYear()}`;

        if (this.overlay !== null) {
            this.map.removeLayer(this.overlay);
        }

        this.overlay = L.imageOverlay(`${HOST}/source/ovd${fileIndex ? `-${fileIndex}` : ''}.svg?v=${window.AR_MAP.version}`, this.rcoords.getMaxBounds());
        this.overlay.addTo(this.map);
    }

    componentDidMount = () => {
        if (this.map === null) {
            const {config, zoom} = this.state;
            L.CRS.Wall = {
                ...L.CRS.EPSG3857, ...{
                    transformation: new L.Transformation(config.transformation),
                }
            };
            this.map = L.map(document.getElementById('map'), {
                crs: L.CRS.Wall,
                minZoom: config["min-zoom"],
                maxZoom: config["max-zoom"],
                attributionControl: false,

            });
            this.map.setView(this.viewToMap(), zoom)
            this.rcoords = new L.RasterCoords(this.map, config["size"])
            this.rcoords.tilesize = config["tile-size"];
            L.tileLayer(`${HOST}/{z}/{y}x{x}.png`, {
                bounds: this.rcoords.getMaxBounds(),
                tileSize: config["tile-size"],
                tms: !1,
                noWrap: !0
            }).addTo(this.map);


            const freeDraw = new FreeDraw({
                strokeWidth: 3,
                concavePolygon: false,
                mergePolygons: false,
                mode: window.location.href.indexOf('freemode') + 1 > 0 ? FreeDraw.ALL : FreeDraw.NONE,
            });
            this.map.addLayer(freeDraw);
            freeDraw.on('markers', event => {
                console.log(event);
                console.log(event.latLngs);
            });




            // var osmUrl = 'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
            //     osmAttrib = '&copy; <a href="http://openstreetmap.org/copyright">OpenStreetMap</a> contributors',
            //     osm = L.tileLayer(osmUrl, { maxZoom: 18, attribution: osmAttrib }),
            //     map = new L.Map('map', { center: new L.LatLng(51.505, -0.04), zoom: 13 }),
            //     drawnItems = L.featureGroup().addTo(map);
            // L.control.layers({
            //     'osm': osm.addTo(map),
            //     "google": L.tileLayer('http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}', {
            //         attribution: 'google'
            //     })
            // }, { 'drawlayer': drawnItems }, { position: 'topleft', collapsed: false }).addTo(map);
            // map.addControl(new L.Control.Draw({
            //     edit: {
            //         featureGroup: drawnItems,
            //         poly: {
            //             allowIntersection: false
            //         }
            //     },
            //     draw: {
            //         polygon: {
            //             allowIntersection: false,
            //             showArea: true
            //         }
            //     }
            // }));
            //
            // map.on(L.Draw.Event.CREATED, function (event) {
            //     var layer = event.layer;
            //
            //     drawnItems.addLayer(layer);
            // });

            let drawnItems = new L.FeatureGroup();
            this.map.addLayer(drawnItems);
            let drawControl = new L.Control.Draw({
                draw: {
                    polyline: false,
                    polygon: {
                        allowIntersection: false,
                        shapeOptions: {
                            stroke: true,
                            color: '#FF0000',
                            weight: 4,
                            opacity: 0.5,
                            fill: true,
                            clickable: true
                        }
                    },
                    circle: {
                        shapeOptions: {
                            stroke: true,
                            color: '#FF0000',
                            weight: 4,
                            opacity: 0.5,
                            fill: true,
                            clickable: true
                        }
                    },
                    circlemarker: false,
                    rectangle: {
                        shapeOptions: {
                            stroke: true,
                            color: '#FF0000',
                            weight: 4,
                            opacity: 0.5,
                            fill: true,
                            clickable: true
                        }
                    },
                    marker: false
                },
                edit: {
                    featureGroup: drawnItems,
                    remove: true
                }
            });
            this.map.addControl(drawControl);
            this.map.on(L.Draw.Event.CREATED, (e) => {
                drawnItems.addLayer(e.layer);
            })


            this.loadEvents();
            this.addMapOverlay();
            this.addCities();
            this.addSeas();
            this.addCountries();
            this.filterMarkers();
            window.zoomedTimeout = 0;
            this.map.on('click', () => {
                this.popupClose();
            })


            // let drawnItems = new L.FeatureGroup();
            // this.map.addLayer(drawnItems);
            // console.log(new L.Control());
            // var drawControl = new L.Control.Draw({
            //     edit: {
            //         featureGroup: drawnItems
            //     }
            // });
            // this.map.addControl(drawControl);

            this.map.on(`movestart`, () => {
                this.popupClose();
            });

            document.getElementById('popup--close').addEventListener('click', (e) => {
                e.preventDefault();
                this.popupClose();
            })

            this.map.on('zoomend', () => {
                this.setState({zoom: this.map.getZoom()}, () => {
                    if (this.state.zoom > this.state.config['min-zoom']) {
                        // clearTimeout(window.zoomedTimeout);
                        // window.zoomedTimeout = setTimeout(() => {
                        const icons = [].slice.call(document.querySelectorAll('#map .marker--icon.event--icon'))
                        icons.forEach(i => {
                            i.classList.add('zoomed');
                        });
                        // }, 0);

                    } else {
                        // clearTimeout(window.zoomedTimeout);
                        // window.zoomedTimeout = setTimeout(() => {
                        const icons = [].slice.call(document.querySelectorAll('#map .marker--icon.event--icon.zoomed'))
                        icons.forEach(i => {
                            i.classList.remove('zoomed');
                        });

                    }
                    this.filterMarkers();

                })
            });
        }
    }

    onDatePrev = () => {
        if (this.state.loading) return;
        let index = this.state.dateIndex + 1;
        if (index > Dates.length - 1) {
            index = Dates.length - 1;
        }
        this.setState({dateIndex: index, selectedDate: new Date(convertDate(Dates[index]))}, () => {
            this.loadEvents();
        });
    }

    onDateNext = () => {
        if (this.state.loading) return;
        let index = this.state.dateIndex - 1;
        if (index < 0) {
            index = 0;
        }
        this.setState({dateIndex: index, selectedDate: new Date(convertDate(Dates[index]))}, () => {
            this.loadEvents();
        });
    }

    onDirectionChange = (direction) => {
        this.setState({direction}, () => {
            // fly to
            let CD = Directions.filter(i => i.id === this.state.direction);
            this.popupClose();
            if (CD && CD[0]) {
                let d = this.map.project(CD[0]);
                this.map.flyTo(this.map.unproject({
                    x: d.x * this.state.config["scale"][0],
                    y: d.y * this.state.config["scale"][1]
                }), 6);

                // this.map.flyTo([CD[0].lat, CD[0].lng], this.state.config['min-zoom'] + 1)
            }
        })
    }


    render() {
        return (
            <div className={`map--layout map--layout__map`}>
                <div className={`map--wrapper`}>
                    <div id={`map`}></div>
                    <div className={`map--popup`} id={"map--popup"}>
                        <a href={"#"} className={`map--popup__close`} id={`popup--close`}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24px" height="24px">
                                <path
                                    d="M 4.9902344 3.9902344 A 1.0001 1.0001 0 0 0 4.2929688 5.7070312 L 10.585938 12 L 4.2929688 18.292969 A 1.0001 1.0001 0 1 0 5.7070312 19.707031 L 12 13.414062 L 18.292969 19.707031 A 1.0001 1.0001 0 1 0 19.707031 18.292969 L 13.414062 12 L 19.707031 5.7070312 A 1.0001 1.0001 0 0 0 18.980469 3.9902344 A 1.0001 1.0001 0 0 0 18.292969 4.2929688 L 12 10.585938 L 5.7070312 4.2929688 A 1.0001 1.0001 0 0 0 4.9902344 3.9902344 z"/>
                            </svg>
                        </a>
                        <div className={`map--popup__content`} id={`map--content`}>

                        </div>
                    </div>
                    <div className="map--controls">
                        <div className="map--controls__row">
                            <div className={`date--picker`}>
                                <button onClick={this.onDatePrev} className={`btn--direction btn-prev`}
                                        disabled={this.state.dateIndex === Dates.length - 1}>
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 7L10 12L15 17" stroke="#000000" strokeWidth="1.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                                <div className={`date--picker__input`}>
                                    <DatePicker
                                        locale={'ar'}
                                        selected={this.state.selectedDate}
                                        dateFormat={"dd.MM.yyyy"}
                                        includeDates={DatepickerDates}
                                        onChange={(date) => {
                                            if (this.state.loading) return;
                                            let index = 0;
                                            for (let i in DatepickerDates) {
                                                if (date.getTime() === DatepickerDates[i].getTime()) {
                                                    index = parseInt(i, 10);
                                                }
                                            }
                                            this.setState({selectedDate: date, dateIndex: index}, () => {
                                                this.loadEvents();
                                            });
                                        }}
                                    />
                                </div>
                                <button onClick={this.onDateNext} className={`btn--direction btn-next`}
                                        disabled={this.state.dateIndex === 0}>
                                    <svg width="800px" height="800px" viewBox="0 0 24 24" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15 7L10 12L15 17" stroke="#000000" strokeWidth="1.5"
                                              strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>
                                </button>
                            </div>
                            <div className={`directions`}>
                                <select name={`directions`} onChange={(e) => {
                                    this.onDirectionChange(e.target.value);
                                }}>
                                    <option value={""}>Выберите направление</option>
                                    {Directions.map(i => {
                                        return <option key={`direction_${i.id}`} value={i.id}>{i.name}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className="map--controls__row legend">
                            <div className={`legend--title`}><a href="#">Условные обозначения <svg width="800px"
                                                                                                   height="800px"
                                                                                                   viewBox="0 0 24 24"
                                                                                                   fill="none"
                                                                                                   xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 7L10 12L15 17" stroke="#000000" strokeWidth="1.5" strokeLinecap="round"
                                      strokeLinejoin="round"/>
                            </svg></a></div>
                            <div className="legend--content">
                                <div className="legend--content__column"></div>
                                <div className="legend--content__column"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
